.App {
  text-align: center;
}

.initial-load {
  animation: fade-in 1s;
}

html, body, #root, #reactapp, #Layout, .App .loading {
  height: 100vh;
}

.notfound, .default-layout {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-black {
  fill: black;
  color: black;
}

.logo-white {
  filter: invert(100%) sepia(99%) saturate(0%) hue-rotate(270deg) brightness(110%) contrast(101%);
}

.star {
  position: absolute;
  height: 120px;
  width: 120px;
}

.ring {
  position: absolute;
  height: 100px;
  width: 100px;
}

.starcircle {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}