.lost-heading {
    font-size: 60px;
    color: white;
    margin-block-end: 0px;
    text-shadow: 0px 0px 10px black;
}

.lost-subheading {
    font-size: 30px;
    color: white;
    text-shadow: 0px 0px 10px black;
}